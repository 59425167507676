import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faBars } from '@fortawesome/free-solid-svg-icons'

function App() {

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);

  return (
    <>
      <div className="Header" style={{display: 'flex'}}>
        <div style={{ display: 'inline-block', paddingLeft: 26, paddingTop: 52, color: 'white', width: 100}}>
          <a href="#" style={{ color: 'white' }} onClick={(e: any) => {
            setIsOpen(!isOpen)
          }}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </a>
        </div>
        <div style={{ display: 'inline-block', margin: 40, marginLeft: 'auto', marginRight: 40 }}>
          <button onClick={() => {
            setIsOpen(!isOpen);
          }}>Toggle Sidebar 1</button>
          <button onClick={() => {
            setIsOpen2(!isOpen2);
          }}>Toggle Sidebar 2</button>
        </div>
      </div>
      <div className="App">
        <div className={classNames("sidebar", { "is-open": isOpen })}>
          {isOpen ? (
            <div style={{ marginLeft: 26, marginTop: 16 }}>
              <a style={{ color: 'white' }} href="#" onClick={(e: any) => {
                console.log('in onclick open with: ', e)
                e.stopPropagation();
                setIsOpen2(!isOpen2)
              }}>sidebar</a>
            </div>

          ) : (
              <div style={{ marginLeft: 316, marginTop: 16 }}>
                <div style={{ textAlign: 'center' }}>
                  <a href="#" style={{ color: 'white' }} onClick={(e: any) => {
                    console.log('in onclick close with: ', e)
                    e.stopPropagation();
                    setIsOpen2(!isOpen2)
                  }}>
                    <FontAwesomeIcon icon={faList} size="2x" />
                  </a>
                </div>
              </div>

            )
          }

        </div>
        <div className={classNames("sidebar2", isOpen ? { "is-open": isOpen2 } : { "is-open-lite": isOpen2 })}>sidebar2</div>
        <div className="Maps" onClick={() => {
          setIsOpen(false);
          setIsOpen2(false);
        }}>
          <p>Maps</p>
        </div>
      </div>
    </>
  );
}

export default App;
